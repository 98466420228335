import React from 'react';
import { useEffect } from 'react';
import { images } from '../../constants';



import './GettingStarted.scss';

const GettingStarted = () => {
  useEffect(()=> {
    window.scrollTo(300,0)
  })
  return (
    <>
    
    <hr className="app_gsnavbar-spacer"></hr>
        <div className={`app_container`}>
      <div className="app_wrapper app_flex">
        <div className="app_gs-profile-title">
        <hr className="app_gs-profile-line"></hr>
          <h2>
            GETTING STARTED
          </h2>
          <hr className="app_profile-line"></hr>
        </div>
      </div>
      </div>
      <div className="app_gs-container">
      <div className="app_gs-items">
        <div className="app_items-text">
          <div className="app_gs-titles">
            <h2 >
              PRELIMINARY INFORMATION
            </h2>
          </div>
          <p>
          Please reach out to our Intake Coordinator with the following preliminary information. You can
reach us by phone at <b>415.655.5922 x102</b> or email at <a href = "mailto:paralegal@amafamilylaw.com">paralegal@amafamilylaw.com</a>. This
information is completely confidential.
          </p>
          <h3 className="app_gs-items-bp">
            <ul>
              <li>
              Your full name (if leaving a voicemail, please spell your last name) and any alternate or
prior names used
              </li>
              <li>
              Your spouse’s full name and any alternate or prior names used
              </li>
              <li>
              For a Premarital Agreement matter, the prospective date of marriage
              </li>
              <li>
              County of residence
              </li>
              </ul>
              <ul>
              <li>
              Ages of your children, if any
              </li>
              <li>
              Whether any case has already been filed and if there are any pending court dates
              </li>
              <li>
              How you were referred to our firm
                </li>
                <li>
                General size of your estate (e.g. more than “X”)
                </li>
                <li>
                Best number to reach you and if it is acceptable for us to leave a message
                </li>
              </ul>
          </h3>
        </div>
          <div className="app_items-text">
          <div className="app_gs-titles">
            <h2 >
              INITIAL CONSULTATION
            </h2>
          </div>
          <p>
          Our Intake Coordinator will run a confidential conflict of interest check and, if no conflict exists,
we will schedule a short, initial consultation, via Zoom or by phone. During this initial
consultation you will be able to discuss your current situation, ask questions about your case and
discuss Ms. Mussallem’s approach and process.
          </p>
          </div>
          <div className="app_items-text">
          <div className="app_gs-titles">
            <h2 >
              FOLLOWING THE INITIAL CONSULTATION
            </h2>
          </div>
          <p>
          Following your initial consultation, if you wish to work with Ms. Mussallem, we will ask you
sign an Engagement Agreement and to provide an initial retainer which will be identified in the
Engagement Letter.
          </p>
          </div>
      </div>
      <div className="app_items-text">
          <div className="app_gs-titles">
            <h2 >
              CONTACT US
            </h2>
          </div>
          <div className = "app_gs-contact-profile">
            <img src={images.karyn} alt="profile" className="app_contact-img"/>
            <div className = "app_gs-contact-text">
              <h3>
                Karyn Roybal
              </h3>
              <p>
                Paralegal
                <br/>
                (t) 415.655-5922 x102
              </p>
              <a href = "mailto:kroybal@amafamilylaw.com">kroybal@amafamilylaw.com</a>
            </div>
            
          </div>
          <p>
          Our office is conveniently located just over the Golden Gate bridge in downtown San Rafael.
          </p>
          <p>
          Mussallem Family Law, P.C.
          <br/>
          999 Fifth Avenue,Suite 350
          <br/>
          San Rafael, CA 94901
          </p>
          </div>
        <div>
        </div>
      </div>
    </>
  )
}

export default GettingStarted;