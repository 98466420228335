import React from 'react'


import './AppWrap.scss';
const AppWrap = (Component, title, idName, classNames) => function HOC() {
  return (
    <div className={`app_container ${classNames}`}>
      <div className="app_wrapper app_flex">
        <div className="app_profile-title">
        <hr className="app_profile-line"></hr>
          <h2>
            {title}
          </h2>
          <hr className="app_profile-line"></hr>
        </div>
        <Component />
      </div>
      </div>
  )
}

export default AppWrap