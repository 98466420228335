import React, {useEffect} from 'react';
import { images } from '../../constants';
import { Link } from 'react-router-dom';
import './Contact.scss';

import { AppWrap } from '../../wrapper';


const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div  className = "app_contact-wrapper">
      <div className = "app_contact-box">
        <div className = "app_contact-profile">
        <div className = "app_contact-profile-item">
            <img src={images.lexi} alt="profile" className="app_contact-img"/>
            <div className = "app_contact-profile-text">
            <h3>
              Lexi Mussallem
            </h3>
            <p>
              (t) 415.655-5922 x101
              <br/>
              (f) 415.639-9548
            </p>
            <a href = "mailto:lexi@amafamilylaw.com">lexi@amafamilylaw.com</a>
            </div>
          </div>
          <div className = "app_contact-profile-item">
            <img src={images.karyn} alt="profile" className="app_contact-img"/>
            <div className = "app_contact-profile-text">
              <h3>
                Karyn Roybal
              </h3>
              <p>
                Paralegal
                <br/>
                (t) 415.655-5922 x102
              </p>
              <a href = "mailto:kroybal@amafamilylaw.com">kroybal@amafamilylaw.com</a>
            </div>
          </div>
        </div>
        <div className = "app_contact-building">
          <img src={images.office} alt="profile"/>
          <p>
            Our office is conveniently located just over the
             Golden Gate bridge in downtown San Rafael.
          </p>
          <p>
            Mussallem Family Law, P.C.
            <br/>
            999 Fifth Avenue, Suite 350
            <br/>
            San Rafael, CA 94901
          </p>
        </div>

      </div>
      <div className="app_contact-button-wrapper">
      
        <Link to="/Getting-Started" ><button >GETTING STARTED</button></Link>
          </div>
    </div>
  )
}

export default AppWrap(Contact, 'CONTACT','contact' );