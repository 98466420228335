import React from 'react'


import './Header.scss';

const Header = () => {
  return (
    <div id="home" className="app_header app_flex">
      
      <div key="home" className="app_header-bgimg">
        <h1 className="app_header-title">
        Family Law
        </h1>
        <h2 className="app_header-subtitle">
        Experience &#x2022; Expertise &#x2022; Compassion
        </h2>
          </div>
          <div id={"about"} ></div>
    </div>
  )
}

export default Header