import React from 'react';

import { images } from '../../constants';

import './Footer.scss'


import './Footer.scss';
const Footer = () => {

  return (
    <>
    <div className="app_footer-wrapper">
      <div className="app_footer-bg">
        <img src = {images.bridge} alt="bridge"/>
      </div>
      <h2 className = "app_footer-legal"> &copy;2022 Mussallem Family Law
      This web page and the information contained herein have been
prepared by Mussallem Family Law, P.C. for informational purposes only
and do not constitute legal advice. The use of this web page, and the
sending or receipt of information does not create an attorney-client
relationship between you and Mussallem Family Law, P.C., Alexandra
Mussallem, or any of the firms employees or agents. Communication
with Mussallem Family Law, P.C. through this website may not be
considered privileged or confidential. This website is a California
Electronic Media Advertisement. It is not intended to be an
advertisement or solicitation outside the state of California. Any
statement, testimonial, or endorsement contained herein does not
constitute a guarantee, warranty, or prediction regarding the ultimate
result or outcome of your legal matter. Any link from this web site to the
website of a private, governmental, educational, or other non-profit
entity’s web page does not state or imply the existence of a relationship
between Mussallem Family Law, P.C., and that entity.
      
      
      
      
      </h2>
      <br/>
      <br/>
    </div>

    </>
  )
}

export default Footer;