import React from 'react';

import { images } from '../../constants';

import {AppWrap} from '../../wrapper';

import './About.scss';

const About = () => {
  return (
    <>
      <div className="app_profiles">
        <img src={images.practice} alt = "profile_bg"className="app_profile-item"/>
        <h2>
          
        ALEXANDRA MUSSALLEM
        </h2>
        <p className="app_about-p-text" style={{marginTop: 20}}>
        Alexandra (“Lexi”) Mussallem was admitted to the State Bar of California in 1997 and has
focused exclusively on family law since her admission. She is a Certified Family Law Specialist,
California Board of Legal Specialization. Lexi graduated Phi Beta Kappa from the University of
California, Los Angeles in 1992, receiving a B.A. in Political Science with an emphasis in Latin
and Classical Literature. From 1989 to 1990, Lexi participated in the University of California
Education Abroad Program studying in Bologna, Padua, and Venice, Italy. After graduating from
UCLA, she returned to Bologna, Italy to live and work. Lexi graduated in 1996 from Hastings
College of the Law in San Francisco.
<br/>
<br/>
Lexi started her legal career with Riede, McCall &amp; Mason in San Rafael as a family law
associate. In 2007, she joined Maple, DeLacey and Mussallem LLP as a partner, practicing in
both San Francisco and Marin Counties. She continued as a partner with Maple &amp; Mussallem
LLP from 2014 until 2021, at which time she formed Mussallem Family Law, P.C. While Lexi’s
office is located in San Rafael, she continues to litigate matters in both San Francisco Superior
Court and Marin County Superior Court and serve clients in alternate dispute resolution
throughout the Bay Area.
<br/>
<br/>
Over the course of her twenty-five year career, Lexi has developed a practice that focuses on the
myriad of ways in which complex financial issues present themselves in the field of family law.
She specializes in complex property division, child support, spousal support, post-judgment
support modifications and enforcement, and prenuptial and postnuptial agreements. She has
extensive experience with diverse compensation and corporate structures, including general and
limited partnership interests, LLC membership interests, incentive and non-qualified stock
options, and restricted stock units. She has represented owners and employees, or their spouses,
of both public and private companies.
<br/>
<br/>
Lexi’s has a thriving practice in both litigation and mediation. Her litigation practice involves
pre-trial, trial, discovery, and motion work. In addition, over the last ten years, Lexi has refined
her alternate dispute resolution skills serving clients as a family law mediator, a consulting
attorney to clients in mediation, and a private settlement judge. Lexi is an AAML Trained
Mediator having recently completed the intensive mediation training sponsored by the American
Academy of Matrimonial Lawyers. Early in her career, she completed the training
provided by the Northern California Mediation Center.
<br/>

<br/>
Lexi is a Fellow of the American Academy of Matrimonial Lawyers, Northern
California Chapter, is certified as a Family Law Specialist by the State Bar of California, Board
of Legal Specialization, and is a member of the Marin County Bar Association as well as the Bar
Association of San Francisco. She has previously served as a co-chair of the AAML Symposium
and been a presenter at the AAML Symposium on the subject of working with a vocational
expert in support cases. For several years, Lexi has mentored new family law trial lawyers as a
Group Leader for the American Academy of Matrimonial Lawyers Trial Practicum. She regularly volunteers for the Marin County
Superior Court in the Bench/Bar Settlement Conference program and for the San Francisco
Superior Court in the Settlement Master program. She has also served as an arbitrator for the
Marin County Bar Association&#39;s Fee Dispute Arbitration Program. Lexi has served on the Board
of Directors for Willow Creek Academy in Sausalito, California as well as on the Board of
Directors for Legal Aid of Marin.
<div className="location-spacer" id={"practice"} ></div>
        </p>
        
      </div>
    </>
  )
}

export default AppWrap(About, 'ABOUT','about' );