import logo from '../assets/logo.jpg'
import background from '../assets/background.png'
import profile from '../assets/profile.png'
import bridge from '../assets/bridge.jpg'
import karyn from '../assets/karyn.jpg'
import lexi from '../assets/lexi.jpg'
import meeting from '../assets/meeting.jpg'
import practice from '../assets/practice.jpg'
import table from '../assets/table.png'
import office from '../assets/office.jpg'
const outimages ={
  logo,
  background,
  profile,
  bridge,
  karyn,
  lexi,
  meeting,
  practice,
  table,
  office
};
export default outimages;