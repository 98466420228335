import React from 'react'

import { About, Header, Footer, Practice, Contact} from './container';
import { Routes, Route} from 'react-router-dom';
import { Navbar, GSNavbar } from './components';
import './App.scss';
import GettingStarted from './container/GettingStarted/GettingStarted';


const App = () => {
  
  return (
    <div className="app">

      <Routes>
        <Route path='/'element=
      {<>
          <Navbar/>
          <Header/>
          <About/>
          <Practice/>
          <Contact/>
          <Footer/>
        </>
      }/>
      
        <Route path='/Getting-Started' element=
        {<>
          
          <GSNavbar/>
          <GettingStarted/>
          <Footer/>
        </>} />
      </Routes>
    </div>
  )
}

export default App