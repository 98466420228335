import React from 'react';

import { images } from '../../constants';

import { AppWrap } from '../../wrapper';

import './Practice.scss';

const Practice = () => {
  return (
    <>
    <div className="app_practice-wrapper">
      <div className="app_practice-title">
      </div>
      <div className="app_practices">
        <div className="app_practice-text">
          <div className="app_practice-text-title">
            <h2 className="ous">
              OUR APPROACH
            </h2>
          </div>
          <p>
          Mussallem Family Law understands the myriad of ways in which divorce or separation affects a
family. With the utmost discretion, we take great care to counsel our clients during each step of
the process in order to maximize successful outcomes. We focus on resolving family law
matters cooperatively and efficiently, where possible, but are ready to litigate skillfully on our
client’s behalf through all stages of the dissolution process, including trial, where necessary. We
are dedicated to providing our clients with the highest level of service through our firm’s depth
of knowledge, years of experience, and the ability to strategize complex family law issues.
          </p>
        </div>
        <img src={images.meeting} alt="profile" className="app_practice-img"/>
      </div>
      {/* */}
      <div className="app_practices">
      <img src={images.table} alt="profile" className="app_practice-img"/>
        <div className="app_practice-text">
          <div className="app_practice-text-title">
            <h2 className="ous">
              OUR LEGAL SERVICES
            </h2>
          </div>
          <p>
          We have extensive background and experience in all aspects of family law, including dissolution
and legal separation actions, post-judgment modifications and enforcement, child and spousal
support matters, and parentage actions.
<br/>
<br/>
We represent clients in traditional litigation as well as consulting with clients, who prefer to
resolve their matters outside of the courthouse. We provide mediation services in all aspects of
dissolution and legal separation. We counsel clients in the drafting and preparation of
premarital and postnuptial agreements.
<br/>
<br/>
We have the skill set and expertise to effectively assist parties in complex dissolution actions
involving various types of assets including, but not limited to, closely held businesses,
substantial and diverse real estate holdings, holdings in public and private companies, General
Partnership and Limited Liability Partnership interests, Limited Liability Company memberships,
equity compensation, cryptocurrency assets, and professional practices.
</p>
        </div>
        
      </div>
      <div className="app_practices">
        <div className="app_practice-text-wrapper">
          <div className="app_practice-text-title">
            <h2 className="pa">
              PRACTICE AREAS
            </h2>
          </div>
          <div className="app_practice-box">
            <div className="app_practice-text-box">
              <h3>
                Litigation
              </h3>
              <p>
              Dissolution, Legal Separation, Domestic Partnership, Parentage, Motion Practice, Pre-Trial and
              Trial, Discovery, Post-Judgment Modifications, Enforcement.
              </p>
              </div>
              <div className="app_practice-text-box">
              <h3>
                  Mediation
                  </h3>
                  <p>
                  Mediation relating to Custody, Support, Property and Fee Issues with Parties Directly or with
        Parties Accompanied by Counsel and other Professionals, Consulting and Advocacy for Clients
        Engaged in the Mediation Process.
                  </p>
                  </div>
          </div>
        </div>
      </div>
      <div className="app_practices">
        <div className="app_practice-text-wrapper">
          <div className="app_practice-box">
          <div className="app_practice-text-box">
          <h3>
            Premarital and Postnuptial Agreements
          </h3>
          <p>
          Negotiation, Preparation, and Drafting of Agreements relating to Separate Property,
          Community Property, Maintenance of Family Wealth, Wealth Building for Couples, Spousal
          Support, and Estate Planning Commitments.
          </p>
          </div>
          <div className="app_practice-text-box">
              <h3>
              Child Support and Spousal Support Actions
              </h3>
              <p>
              Income Available for Support, Extraordinarily High Earner Exception, Imputation of Earned or
              Passive Income, Marital Standard of Living, Enforcement.
              </p>
          </div>
          </div>
        </div>
        
      </div>
      <div className="location-spacerc"  id={"contact"} ></div>
    </ div>
    </>
  )
}

export default AppWrap(Practice, 'THE PRACTICE','practice' );