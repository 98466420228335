// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --font-base: 'Helvetica Neue', 'Arial', sans-serif;\n\n  --header-color: #00175a;\n  --subheader-color: #58595b;\n  --spacer-color: #69bd45;\n}\n\n* {\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  scroll-behavior: smooth;\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,kDAAkD;;EAElD,uBAAuB;EACvB,0BAA0B;EAC1B,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,UAAU;EACV,SAAS;EACT,uBAAuB;AACzB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext\");\n\n:root {\n  --font-base: 'Helvetica Neue', 'Arial', sans-serif;\n\n  --header-color: #00175a;\n  --subheader-color: #58595b;\n  --spacer-color: #69bd45;\n}\n\n* {\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  scroll-behavior: smooth;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
